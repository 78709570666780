import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import BlogArchiveRow from '../../components/BlogArchiveRow'

const BlogIndex = ({ data, location }) => {
  return (
    <Layout pageData={data.strapiBlog}>
      <Seo
        title="Blog archief 2011 - 2017"
        description="Het wel en wee van Van het Wilmershof"
      />

      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li className="is-active">
            <Link to="/blog-archive">Blog archief 2011 - 2017</Link>
          </li>
        </ul>
      </nav>

      <BlogArchiveRow />
    </Layout>
  )
}
export default BlogIndex

export const pageQuery = graphql`
  query {
    strapiBlog {
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
  }
`